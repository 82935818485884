.btnlights {
  background-color: rgb(150 106 77);
  color: #fff !important;
  border: 1px;
  border-radius: 15px;
  padding: 13px 16px;
  padding: 13px 16px;
  transition: 0.5s;
  border: 1px solid var(--title-color) !important;
}

.btnlights:hover {
  background-color: transparent;
  color: var(--title-color) !important;
}

.heading__top.ht_heading {
  position: relative;
  margin: 0 20px;
}

.investor_profile {
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 10%), 0 0.25rem 0.53125rem rgb(90 97 105 / 12%), 0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
}

.investor_profile.formPopUp h4 {
  padding: 5px 0;
  line-height: normal;
  display: flex;
  align-items: center;
}

.investor_profile.formPopUp .ICON__Arrow {
  background: transparent;
  border: 0;
  color: #fff;
  height: auto;
}

.investor_tabning {
  padding: 15px;
  max-width: 773px;
  margin-right: auto;
  /* border-bottom: 1px solid #966A4D; */
}

.investor_tabning .nav-pills {
  border-bottom: 1px solid #966A4D;
  flex-direction: row !important;
}

.investor_profile .nav-link.active {
  background: transparent;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 0 14px;
}

.investor_tabning .nav-item a {
  padding: 0px 14px;
}

.sent_email_btn {
  font-size: 14px;
  padding: 2px 10px;
  background: transparent;
  color: #966A4D !important;
  border: 1px solid;
  /* float: right; */
}

.investor_profile .tab-pane {
  border-top: 0;
}

.investor_tabning .fa-pencil,
.role_edit {
  padding: 7px 20px;
  background: #966A4D;
  border-radius: 20px;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  /* margin: 15px 0; */
}

.investor_profile_form_page {
  margin: 20px;
}

.about.modal-body,
.address_update.modal-body,
.email_updated_pro.modal-body {
  height: auto;
}

.sel-account {
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 30px;
  /* opacity: 0; */
  padding: 10px 10px 10px 20px !important;
  /* position: relative; */
  position: absolute;
  top: 25px;
  left: 0;
  background: transparent;
  opacity: 0;
  z-index: 1;
}

.nav_logo_main .invetor_loader .loader {
  border: 3px solid var(--title-bg_color);
  border-top: 3px solid var(--border-color);
  width: 30px;
  height: 30px;
  top: -33px;
  right: 3px;
  position: absolute;
}

.invetor_loader {
  position: relative;
  /* margin-bottom: 20px; */
}

.veryfy_number .veryfy_btn .main-loader .loader {
  width: 30px;
  height: 30px;
  border: 4px solid #966A4D;
  border-top: 4px solid #DEE2E6;
  right: 0 !important;
  left: -55%;
  top: -35px !important;
  z-index: 9;
}

.right_content .form {
  margin: 20px !important;
}

h4.drop_icon.Completed_apllicatin_icon {
  width: 100%;
  max-width: 270px;
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.heading__top h2 {
  padding: 0 !important;
}

.custom_card {
  min-height: 220px;
  border-radius: 5px;
}

.text_accordion .col-6 {
  width: 100%;
}

.notificaion_list {
  position: absolute;
  right: 22px;
  right: 0;
  max-width: 421px;
  box-shadow: 0 5px 5px #C7C7C7;
  background: #fff;
  height: auto;
  top: 115px;
  width: 100%;
}

.notificaion_body {
  padding: 0 20px 20px;
  overflow-y: scroll;
  max-height: 500px;
}

.notificaion_items p {
  color: #6b778c;
  text-overflow: ellipsis;
  width: 330px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.notificaion_items small {
  color: #6b778c;
}

.notificaion_items {
  border-bottom: 1px solid #E5E5E5;
  padding: 20px 0 5px;
}

.notificaion_items .nofi_icon i {
  font-size: 40px;
  color: var(--text-color);
}

.notifi_content h5 {
  font-size: 16px;
  text-transform: capitalize;
  color: #172b4d !important;
}

.notificaion_list h3 {
  padding: 10px 20px;
  background: var(--title-bg_color);
  color: #FFFFFF !important;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nofi_icon {
  width: 12%;
}

.notifi_content {
  width: 88%;
}

.cross_icon {
  cursor: pointer;
}

p.swal_peragraph {
  text-align: center;
  line-height: 22px;
}

.select_account {
  position: relative;
}

.select_account:after {
  content: '\f107';
  position: absolute;
  right: 3px;
  top: 29px;
  color: var(--title-color);
  font-family: 'FontAwesome';
}

.dashbord_popup {
  background: transparent !important;
  border: 0;
}

.dashbord_popup button::after {
  font-size: 30px;
}

.dashbord_popup_footer {
  border: 0;
  justify-content: center;
}

.dashbord_popup_footer a {
  border: 2px solid var(--title-color);
  width: 82px;
  text-align: center;
  background: var(--title-color);
  color: #fff !important;
  width: auto !important;
  border-radius: 15px !important;
  padding: 9px 25px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

.show_notification {
  position: absolute;
  background: transparent;
  border: 0;
  padding: 8px;
  width: 36px !important;
  height: 27px;
  border-radius: 50% !important;
  font-weight: 600;
  top: -6px;
  right: -6px;
  color: green !important;
  font-size: 34px;
}

.header_TP .show_notification:hover {
  /* background-color: transparent !important; */
  color: green !important;
}

.show_notification i::after {
  content: "New";
  position: absolute;
  left: 8px;
  top: 13px;
  font-family: fontAwesome;
  font-size: 8px;
  color: white;
}

/***** 22 Mar Css *****/
@media screen and (max-width: 479px) {
  .fa-comment:before {
    display: none;
  }

  .show_notification i::after {
    position: absolute;
    content: ".";
    color: green;
    font-size: 50px;
    top: -47px;
  }
}

/***** End 22 Mar Css *****/