* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/***** Banner Section *****/

.video_sect sapn {
  color: #fff;
}

.main {
  margin-top: -50px;
  /* margin-right: -20px; */
}

.banner-text:first-child p {
  border-bottom: 2px solid;
  padding: 20px;
  font-weight: 800;
}

.bgimg img {
  width: 100%;
  height: 400px;
  object-fit: none;
}

.video_sect {
  width: 20%;
  /* display: flex; */
  /* align-items: center; */
  /* height: 155px; */
  border-top: 2px solid #fff;
  margin-top: 24px;
  padding-top: 40px;
}

.left_text p {
  border-bottom: 2px solid #fff;
}

.left_text {
  width: 75%;
}

.banner-text h1 {
  font-size: 40px;
  color: #fff !important;
  margin-top: 34px;
}

.banner-text h3 {
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #fff !important;
}

.banner-text {
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video_sect span {
  font-weight: 600;
}

.video_sect button {
  border-color: transparent !important;
  /* border-width: 2px;
   margin-right: 15px;
  width: 50px;
  height: 46px;
  border-radius: 50% !important; */
  background-color: transparent !important;
  transition: 0.5s;
}

.video_sect .svg_img {
  border-color: #ffffff !important;
  border-width: 2px;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background-color: transparent !important;
  transition: 0.5s;
  display: inline-block;
  /* position: relative; */
  /* z-index: 9999; */
  line-height: 50px;
  border: 1px solid;
  text-align: center;
}

.video_sect button .svg_img:hover {
  background-color: hsla(0, 0%, 100%, 0.2) !important;
}

/***** End Banner Section *****/

/***** ftr Section *****/
.feature {
  display: flex;
  flex-wrap: wrap;
  /* width: 80%; */
  margin: 50px auto;
}

.ftr-cntnt {
  flex: 30%;
  padding: 30px;
}

.img_vedio {
  position: relative;
}

.img_vedio .video_sect {
  height: auto;
  position: absolute;
  bottom: 15px;
  left: 15px;
  border: 0 !important;
}

.ftr-cntnt-2 {
  flex: 60%;
  padding: 30px;
}

.ftr-cntnt-2 b {
  margin-bottom: 20px;
  display: block;
}

/* .ftr-cntnt-2 p {
  padding-top: 20px;
}  */
.ftr-txt {
  border-top: 1px solid;
  padding: 20px 0;
}

.ftr-txt p {
  display: inline-block;
  margin: 0;
  color: #414042;
}

.ftr-inner-cont h3 {
  margin-bottom: 8px;
  margin-top: 8px;
  color: #414042 !important;
  font-size: 40px;
}

.contact-contant {
  display: flex;
}

address {
  margin-left: 32px;
  padding: 20px 0;
  font-style: normal;
}

.contact-contant img {
  width: 100px;
  padding: 20px 0;
}

.ftr-txt.ftr-txt-cont p {
  font-weight: 700;
}

/***** End ftr Section *****/

/***** 'img-section Section *****/
/* .pctrs {
  width: 50%;
  float: right;
  padding: 30px;
  position: relative;
  top: -350px;
} */
.pcr-text {
  padding: 29px 0;
}

/* .pcr-text h4 {
  padding: 19px 0;
} */
.pcr-cntnt img {
  width: 100%;
}

.pcr-cntnt h4 {
  font-size: 28px;
}

.pcr-cntnt p span {
  text-decoration: underline;
}

.pcr-cntnt:last-child p {
  border-top: 1px solid;
  padding-top: 22px;
  font-size: 14px;
}

.infoDetails_btns {
  margin: 30px !important;
}

/***** End 'img-section Section *****/

@media screen and (max-width: 991px) {

  .ftr-cntnt,
  .ftr-cntnt-2 {
    flex: 100%;
  }

  /* .pctrs{
    top: -100px;
    float: none;
    width: 80%;
    margin: 0 auto;
  } */
  .ftr-inner-cont {
    float: right;
  }

  .left_text {
    width: 93%;
  }

  .video_sect {
    width: 100%;
    border: 0;
    padding-top: 0;
  }

  .ftr-txt {
    border-bottom: 1px solid #414042;
    border-top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0;
  }

  .ftr-inner-cont h3 {
    font-size: 30px;
    text-align: right;
  }

  .ftr-txt p {
    font-size: 16px;
  }

  .banner-text {
    width: 93%;
    left: 15px;
  }

}

@media screen and (max-width: 991px) and (min-width:767px) {
  .main {
    margin-top: -81px;
  }

  /***** Banner Section *****/

  /***** End Banner Section *****/
}

@media screen and (max-width: 767px) {

  /***** Banner Section *****/
  .banner-text h1 {
    font-size: 30px;
  }

  .banner-text h3 {
    font-size: 20px;
  }

  .ftr-inner-cont h3 {
    font-size: 20px;
    text-align: right;
  }

  .ftr-txt p {
    font-size: 16px;
  }

  /***** End Banner Section *****/
}

@media screen and (max-width:576px) {
  .ftr-txt {
    flex-wrap: wrap;
  }

  .ftr-txt p {
    width: 100%;
  }

  .ftr-inner-cont {
    width: 100%;
    float: left;
  }

  .ftr-inner-cont h3 {
    text-align: left;
  }

  .ftr-cntnt {
    padding: 15px;
  }

  .ftr-cntnt-2 {
    padding: 15px;
  }

  .infoDetails_btns {
    margin: 15px !important;
  }

  .ftr-cntnt-2 b {
    text-align: justify;
  }

  .ftr-cntnt-2 p {
    text-align: justify;
  }

  .pcr-cntnt h4 {
    font-size: 20px;
    text-align: center;
  }
}



.video_sect .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent !important;
}

.video_sect .modal-content {
  max-width: 638px;
  border-bottom: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
  border-radius: 0;
  margin: 15px;
}

.video_sect .modal-content .btn-close {
  position: absolute;
  color: #ffff !important;
  font-size: 24px;
  z-index: 9;
  left: -13px;
  top: -34px;
}

.video_sect .modal-content video {
  width: 100%;
  /* max-width: 638px; */
  height: auto;
}

.modal-open .modal {
  background-color: #00000075;
  z-index: 9999;
}

.btn-close:focus {
  box-shadow: none !important;
}

/* --- 16 feb css --- */
.dataTables_scroll {
  margin-bottom: 10px;
}

button.btn.send_notification {
  margin-top: 15px;
}
input[type=checkbox] {
  accent-color: #966a4d;
}
/* --- End 16 feb css --- */